/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import { userService } from '../../services'
import { get, set, immutable_set, post_message, addParentSiteMetatag, addUserId, checkForIframe } from "../../utils";
import { makeSetAuthenticationStateAction, makeSetChatBotAsHomepage, makeSetClientConfigurationAction } from "../LoginPage/login-page-redux";
import { makeGlobalClearStateThunk, makeSetErrorMessageAction } from "../LogoutPage/logout-page-redux";

import { actionType } from 'redux-postmessage-middleware';
import { handleError, handleErrorStatuses } from "../../utils/internal-error-handler";

import {
    makeSetErrorModalAction
} from "../../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";
import { GENERIC_ERROR_MESSAGE } from "../../components/common/constants";
import {account_data, trackAccountEvents} from "../../utils/analytics-tracker-utils";

const defaultTokenLoginPageState = {
    mdxAuthenticationJson: {},
    errorMessage: ''
}

const PATH_TO_MDX_AUTHENTICATION_JSON = 'mdxAuthenticationJson';

// Action constants
export const A = {
    SET_TOKEN_AUTHENTICATION_STATE: 'SET_TOKEN_AUTHENTICATION_STATE',
    SET_TOKEN_ERROR_MESSAGE: 'SET_TOKEN_ERROR_MESSAGE',
    CLEAR_TOKEN_AUTHENTICATION_STATE: 'CLEAR_TOKEN_AUTHENTICATION_STATE',
    SET_CLEAR_LOGIN_STATE: 'SET_CLEAR_LOGIN_STATE',
    SET_LOGIN_PROCESS_FINISHED: 'SET_LOGIN_PROCESS_FINISHED',
    SET_SHOULD_NOT_APPLY_DEFAULT_STYLES: 'SET_SHOULD_NOT_APPLY_DEFAULT_STYLES'
}


// Action Creators
export function makeClearTokenAuthenticationState() {
    return {
        type: A.CLEAR_TOKEN_AUTHENTICATION_STATE
    }
}

export function makeSetTokenLoginAuthenticationStateAction(authenticationJson = {} ,shouldNotApplyDefaultStyles = false) {
    if (!shouldNotApplyDefaultStyles) {
        writeStyles(authenticationJson);
    }
    addUserId(authenticationJson);
    checkForIframe();
    addParentSiteMetatag(authenticationJson);
    return {
        type: A.SET_TOKEN_AUTHENTICATION_STATE,
        authenticationJson
    }
}

export function makeSetTokenLoginErrorMessageAction(errorMessage = '') {
    return {
        type: A.SET_TOKEN_ERROR_MESSAGE,
        errorMessage
    }
}

export function makeSetLoginProcessFinishedAction(isLoginProcessFinished = false) {
    return {
        type: A.SET_LOGIN_PROCESS_FINISHED,
        isLoginProcessFinished
    }
}

export function makeSetShouldNotApplyDefaultStylesAction(shouldNotApplyDefaultStyles = false) {
    return {
        type: A.SET_SHOULD_NOT_APPLY_DEFAULT_STYLES,
        shouldNotApplyDefaultStyles
    }
 }

export function writeStyles(authenticationJson = {}, overrideCss = "") {
    var customCSS = get(authenticationJson, 'Client_Config.custom_ui.overridecss', '')
    if (customCSS || overrideCss) {
        var styleTags = document.querySelectorAll('[role=custom]');
        for (var i = 0; i < styleTags.length; i++) {
          styleTags[i].parentNode.removeChild(styleTags[i]);
        }
        var styleElement = document.createElement('style');
        styleElement.role = "custom"
        styleElement.type = 'text/css';
        styleElement.appendChild(document.createTextNode(customCSS ? customCSS : overrideCss))
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
}

export function makeIFrameLoginThunk(gatewayPageProps = {}) {
    return (dispatch, getState) => {
        dispatch(makeSetErrorModalAction({ show_toast_modal: false, error_type: "" }))
        dispatch(makeSetErrorModalAction({ show_modal: false }))
        dispatch(makeSetLoginProcessFinishedAction(false));
        const shouldShowErrorPopup = true;
        return userService.iFrameLogin(gatewayPageProps, getState())
            .then(function (response) {
                if (response instanceof TypeError) {
                    handleError({
                        props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup,
                        dispatch, errorPopupAction: makeSetErrorModalAction({ show_toast_modal: true, error_type: "TOKEN" })
                    });
                }
                return { "data": response.json(), "status": response.status };
            }).then(function (response) { //NOTE not the full response
                if (response.status === 200) {
                    const response_data = response.data
                    console.debug("response data: ", response_data);
                    console.debug("Token Authentication is success...! response.status==200");
                    return response.data
                } else if (response.status === 403) {
                    const failureMsg = { name: "MDXWatsonError", errorType: "authentication", message: "Token authentication Failed" };
                    post_message(externalFailureMessage(failureMsg));
                    console.error("I got a successful response but did not log in! response.status==403");
                    handleError({
                        props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup,
                        dispatch, errorPopupAction: makeSetErrorModalAction({ show_toast_modal: true, error_type: "TOKEN" })
                    });
                    return Promise.resolve();
                } else if (response.status === 401) {
                    const failureMsg = { name: "MDXWatsonError", errorType: "authentication", message: "IP authentication Failed" };
                    post_message(externalFailureMessage(failureMsg));
                    console.error("I got a successful response but did not log in! response.status==401");
                    const subscription_state = get(getState().login_page, 'mdxAuthenticationJson', {})
                    const isIpAuthenticated = get(subscription_state, 'ipAuthenticated', false)
                    if (isIpAuthenticated) {
                        handleErrorStatuses(response.status, gatewayPageProps, dispatch, getState)
                    } else {
                        handleError({
                            props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup,
                            dispatch, errorPopupAction: makeSetErrorModalAction({ show_toast_modal: true, error_type: "IP" })
                        });
                    }
                    return Promise.resolve();
                } else if (response.status === 500) {
                    const failureMsg = { name: "MDXWatsonError", errorType: "authentication", message: "Token authentication Failed" };
                    dispatch(externalFailureMessage(failureMsg));
                    console.error("user has no PWA subscription! response.status==500");
                    handleError({ props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldRedirectToErrorPage: true });
                    return Promise.resolve();
                } else {
                    const failureMsg = { name: "MDXWatsonError", errorType: "authentication", message: "Token authentication Failed" };
                    dispatch(externalFailureMessage(failureMsg));
                    console.error("UNHANDLED response.status==", response.status)
                    handleError({
                        props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup,
                        dispatch, errorPopupAction: makeSetErrorModalAction({ show_toast_modal: true, error_type: "TOKEN" })
                    });
                    return Promise.resolve();
                }
            }).then(function (response_data) {
                if (response_data !== undefined && response_data !== null) {
                   let secured_origin = get(response_data, 'Client_Config.secured_origins');
		   let host;
                   try {
                     host = (window.location !== window.parent.location) ? document.referrer : document.location.href;
                   } catch (e) {
                     host = secured_origin;
                   }
                  if (host && host !== secured_origin) {
                     set(response_data, 'Client_Config.secured_origins', host);
                   }

                    trackAccountEvents(response_data)
                    account_data(response_data);
                    const shouldNotApplyDefaultStyles = get(getState(), "gateway_login_page.shouldNotApplyDefaultStyles", false);
                    dispatch(makeSetTokenLoginAuthenticationStateAction(response_data, shouldNotApplyDefaultStyles));
                    dispatch(makeSetAuthenticationStateAction(response_data));
                    const homepage = get(response_data.Client_Config, 'homepage', []);
                    const chatBotAsHomepage = get(response_data.Client_Config, 'chatBotAsHomepage') === undefined ?
                    (homepage.find(page => page.chatBotAsHomepage !== undefined)?.chatBotAsHomepage ?? false)
                    : get(response_data.Client_Config, 'chatBotAsHomepage', false);

                    dispatch(makeSetChatBotAsHomepage(chatBotAsHomepage));

                    const searchAsHomePage = get(response_data.Client_Config, 'chatBotAsHomepage') === undefined ?
                    (homepage.find(page => page.searchAsHomePage !== undefined)?.searchAsHomePage ?? false) :
                                             get(response_data.Client_Config, 'searchAsHomePage', !chatBotAsHomepage);
                    const hasDrugInteractions = get(response_data, 'drugInteractions');
                    const hasIvCompatibility = get(response_data, 'IVCompatibilitySolutionTPNTNA');

                    // Based on client-config properties, setting appropriate page
                    var pageRedirect;
                    if(chatBotAsHomepage){
                        pageRedirect = '/chatbot';
                    }
                    else if(searchAsHomePage){
                        pageRedirect = '/home';
                    }
                    else if(hasDrugInteractions){
                        pageRedirect = '/drugInteractionSearch';
                    }
                    else if(hasIvCompatibility){
                        pageRedirect = '/ivCompatibilitySearch';
                    }
                    else{
                        pageRedirect = '/errorpage';
                    }

                    let secured_origins = get(response_data, 'Client_Config.secured_origins');
                    console.debug('makeTokenLoginThunk: secured_origins=', secured_origins);

                    const successMsg = { name: "MDXWatsonAuthenticationSuccess" };
                    post_message(externalSuccessMessage(successMsg), secured_origins);
                    gatewayPageProps.history.push({
                        pathname: pageRedirect,
                        search: '',
                        state: { response_data: response_data }
                    });
                }
                dispatch(makeSetLoginProcessFinishedAction(true));
                return Promise.resolve();
            }).catch((err) => {
                const failureMsg = { name: "MDXWatsonError", errorType: "authentication", message: "Token Authentication Failed" };
                post_message(externalFailureMessage(failureMsg));
                console.error("Login failed.", err);
                handleError({
                    props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup,
                    dispatch, errorPopupAction: makeSetErrorModalAction({ show_toast_modal: true, error_type: "TOKEN" })
                });
                dispatch(makeSetLoginProcessFinishedAction(true));
                return Promise.resolve();
            })
    }
}

export function makeTokenLogoutThunk(gatewayPageProps = {}) {
    return (dispatch, getState) => {
        userService.logout(gatewayPageProps, get(getState(), 'login_page.mdxAuthenticationJson.Set-Cookie', ''))
            .then(function (response) {
                let current_state = getState();
                console.debug('current state in side logout Thunk :', current_state);
                dispatch(makeGlobalClearStateThunk())
                current_state = getState();
                console.debug('After clearing current state in side logout Thunk :', current_state)
                return Promise.resolve();
            }).catch((err) => {
                console.error("Logout failed.", err); // TODO: better error handling/logging
                handleError({ props: gatewayPageProps, message: GENERIC_ERROR_MESSAGE, dispatch },
                    makeSetErrorMessageAction(err));
                return Promise.resolve();
            });
    }
}

export function getTokenLoginClientConfigurationThunk(gatewayPageProps = {}) {
    return (dispatch) => {
        userService.getClientConfiguration(gatewayPageProps).then(
            function (json) {
                dispatch(makeSetClientConfigurationAction(json));
            })
        // TODO: error handling?
    }
}


// Reducers
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function updateSetAuthenticationStateReducer(current_state = {}, action = {}) {
    let new_state = immutable_set(
        current_state,
        PATH_TO_MDX_AUTHENTICATION_JSON,
        action.authenticationJson
    )
    return new_state;
}

export function updateSetErrorMessageReducer(current_state = {}, action = {}) {
    let new_state = immutable_set(
        current_state,
        'errorMessage',
        action.errorMessage
    )
    return new_state;
}

export function clearGatewayStateReducer() {
    return {
        mdxAuthenticationJson: {},
        errorMessage: ""
    };
}

export function setLoginProcessFinishedReducer(current_state = {}, action = {}) {
    return immutable_set(
        current_state,
        "isLoginProcessFinished",
        action.isLoginProcessFinished
    )
}

export function setApplyDefaultStylesReducer(current_state={},action={}) {
    return immutable_set(current_state, 'shouldNotApplyDefaultStyles', action.shouldNotApplyDefaultStyles);
}

// NOTE: reducers can be used to initialize state
export function gateway_login_page(
    current_state = defaultTokenLoginPageState,
    action = {}
) {
    switch (action.type) {
        case A.SET_TOKEN_AUTHENTICATION_STATE:
            return updateSetAuthenticationStateReducer(current_state, action)
        case A.SET_TOKEN_ERROR_MESSAGE:
            return updateSetErrorMessageReducer(current_state, action)
        case A.CLEAR_TOKEN_AUTHENTICATION_STATE:
            return clearGatewayStateReducer(current_state, action);
        case A.SET_LOGIN_PROCESS_FINISHED:
            return setLoginProcessFinishedReducer(current_state, action);
        case A.SET_SHOULD_NOT_APPLY_DEFAULT_STYLES:
            return setApplyDefaultStylesReducer(current_state, action);
        default:
            return current_state
    }
}

const externalSuccessMessage = (data) => ({
    type: actionType('EXTERNAL'),
    name: data.name
});
const externalFailureMessage = (data) => ({
    type: actionType('EXTERNAL'),
    name: data.name,
    errorType: data.errorType,
    message: data.message
});
